import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Badge,
  Form,
  DropdownButton,
  Dropdown,
  Button,
  InputGroup,
} from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/actions/userActions.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCategories } from "../redux/actions/categoryActions.js";
import socketIOClient from "socket.io-client";
import { setChatRooms, setSocket, setMessageReceived, removeChatRoom } from "../redux/actions/chatActions.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faXTwitter } from "@fortawesome/free-brands-svg-icons"
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons"


const config = require("../constants/constants.js")
let site_style_active = config.SITE_STYLE_ACTIVE;

const HeaderComponent1styleA = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userRegisterLogin);
  const itemsCount = useSelector((state) => state.cart.itemsCount);
  const { categories } = useSelector((state) => state.getCategories);
  const { messageReceived } = useSelector((state) => state.adminChat);

  const [searchCategoryToggle, setSearchCategoryToggle] = useState("Tutte le categorie");
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const url_customer_image_logo = config.URL_IMAGES_CUSTOMER+"logo-cust"+config.CUSTOMER_CODE+".png";  // logo-cust0001.png 

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const submitHandler = (e) => {
     if (e.keyCode && e.keyCode !== 13) return;
     e.preventDefault();
     if (searchQuery.trim()) {
         if (searchCategoryToggle === "Tutte le categorie") {
             navigate(`/product-list/search/${searchQuery}`);
         } else {
             navigate(`/product-list/category/${searchCategoryToggle.replaceAll("/", ",")}/search/${searchQuery}`);
         }
     } else if (searchCategoryToggle !== "Tutte le categorie") {
         navigate(`/product-list/category/${searchCategoryToggle.replaceAll("/", ",")}`);
     } else {
         navigate("/product-list");
     }
  }

  useEffect(() => {
      if (userInfo.isAdmin) {
          var audio = new Audio("/audio/chat-msg.mp3");
          const socket = socketIOClient();
          socket.emit("admin connected with server", "Admin" + Math.floor(Math.random() * 1000000000000));
          socket.on("server sends message from client to admin", ({user, message}) => {
              dispatch(setSocket(socket));
        //   let chatRooms = {
        //     fddf54gfgfSocketID: [{ "client": "dsfdf" }, { "client": "dsfdf" }, { "admin": "dsfdf" }],
        //   };
            dispatch(setChatRooms(user, message));      
             dispatch(setMessageReceived(true));  
             audio.play();
          })
          socket.on("disconnected", ({reason, socketId}) => {
            //   console.log(socketId, reason)
            dispatch(removeChatRoom(socketId));
          })
          return () => socket.disconnect();
      }
  },[userInfo.isAdmin])

// CAMBIO STILE DEL SITO : goto subdomains
// 
//    switch stile: 
//        CLASSIC     A       subdomain   classic.myideaonline.it
//        IMPACT      B       subdomain   impact.myideaonline.it
//        MODERN      C       subdomain   modern.myideaonline.it
//        SUMMER      D       subdomain   summer.myideaonline.it
// 
const handleSelectStyle=(value)=>{
    console.log('Style change in progress!')
    //console.log(value);
    site_style_active=value;
    //console.log(site_style_active)
    if(site_style_active === 'A'){
      //console.log('goto Classic style')
      window.location = "https://classic.myideaonline.it/"
    } else if(site_style_active === 'B') {
      //console.log('goto Impact style')
      window.location = "https://impact.myideaonline.it/"
    } else if(site_style_active === 'C') {
      //console.log('goto Modern style')
      window.location = "https://modern.myideaonline.it/"
    } else if(site_style_active === 'D') {
      //console.log('goto Summer style')
      window.location = "https://summer.myideaonline.it/"
    }
  } 

  // CAMBIO STILE DEL SITO
  // 
  //    se config.ENABLE_SWITCH_STILE = true   => appare un combobox per la selezione dello stile e poi va al subdomain      
  //    se config.ENABLE_SWITCH_STILE = false  => non appare un combobox per la selezione dello stile       
  //
  function SwitchStile(props) {
    if (!props) {
      return null;
    }
    return (
    <NavDropdown title="Stile" id="collasible-nav-dropdown" onSelect={handleSelectStyle}>
        <NavDropdown.Item eventKey="A"> Classic </NavDropdown.Item>
        <NavDropdown.Item eventKey="B"> Impact </NavDropdown.Item>
        <NavDropdown.Item eventKey="C"> Modern </NavDropdown.Item>
        <NavDropdown.Item eventKey="D"> Summer </NavDropdown.Item>
    </NavDropdown>
    );
  }

       {/* 
              PER ESSERE RESPONSIVE ANCHE NELLE 2 RIGHE DI MENU 
              devo gestire 
                height: "40px",
                height: "auto",
        */}


  return (

    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      
      <Container>

        <LinkContainer to="/">
          <Navbar.Brand href="/">
            <img src={url_customer_image_logo} alt="bug" height={50} />       
          </Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
       
        <Navbar.Collapse id="responsive-navbar-nav">     

            <Nav className="justify-content-end" style={{ width: "100%" }}>
              
              <LinkContainer to="/home">
                      <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/prodotti">
                      <Nav.Link>Prodotti</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/expertise">
                      <Nav.Link>Expertise</Nav.Link>
              </LinkContainer>
              <NavDropdown title="Eventi" id="collasible-nav-dropdown">
                <LinkContainer to="/convegni-eventi">
                  <NavDropdown.Item> Eventi importanti </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/convegni-argomenti">
                  <NavDropdown.Item> Argomenti </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/convegni-date">
                  <NavDropdown.Item> Prossime date </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <LinkContainer to="/contatti">
                      <Nav.Link>Contatti</Nav.Link>
              </LinkContainer>

            </Nav>

          <Nav>

            {userInfo.isAdmin ? (
              <LinkContainer to="/admin/orders">
                <Nav.Link>
                  Admin
                  {messageReceived && <span className="position-absolute top-1 start-10 translate-middle p-2 bg-danger border border-light rounded-circle"></span>}
                  
                </Nav.Link>
              </LinkContainer>
            ) : userInfo.name && !userInfo.isAdmin ? (
              <NavDropdown
                title={`${userInfo.name} ${userInfo.lastName}`}
                id="collasible-nav-dropdown"
              >

        {/* 
                <NavDropdown.Item
                  eventKey="/user/my-orders"
                  as={Link}
                  to="/user/my-orders"
                >
                  Ordini
                </NavDropdown.Item>
        */}

                <NavDropdown.Item eventKey="/user" as={Link} to="/user">
                  Profilo
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => dispatch(logout())}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <LinkContainer to="/login">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
              </>
            )}

          </Nav>

          <Nav>

            <LinkContainer to="#">
              <Nav.Link>
                <FontAwesomeIcon icon={faFacebook} 
                                  style={{
                                    marginLeft: "10px",
                                    color: "white",
                                    height: "30px",
                                }}
              
                ></FontAwesomeIcon>
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to="#">
              <Nav.Link>
                <FontAwesomeIcon icon={faInstagramSquare}  
                                  style={{
                                    marginLeft: "10px",
                                    color: "white",
                                    height: "30px",
                                }}
              
                ></FontAwesomeIcon>
             </Nav.Link>
            </LinkContainer>

            <LinkContainer to="#">
              <Nav.Link>
                <FontAwesomeIcon icon={faTwitter}  
                                  style={{
                                    marginLeft: "10px",
                                    color: "white",
                                    height: "30px",
                                }}
              
                ></FontAwesomeIcon>
             </Nav.Link>
            </LinkContainer>

            {/*     CAMBIO STILE DEL SITO : appare il combobox di selezione Stile     */}
            {SwitchStile(config.ENABLE_SWITCH_STILE)}

          </Nav>

        </Navbar.Collapse>

      </Container>

    </Navbar>

);
};

export default HeaderComponent1styleA;
