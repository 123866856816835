//const DB_URI = "mongodb+srv://xxx:xxx@cluster0.xxx.mongodb.net/?retryWrites=true&w=majority";
//const API = process.env.NODE_ENV === "production"
//    ? "https://xxx.vercel.app/api"
//    : "http://localhost:3000/api";

//const GOOGLE_CLIENT_ID = "xxx-xxx.apps.googleusercontent.com";
//const GOOGLE_CLIENT_SECRET = "xxx";

// identificazione MyStudio
const TITLE_PRODUCT = "MyIdea";

// impostazione stile SITO e abilitazione comboBox x cambiarlo
let SITE_STYLE_ACTIVE="B"
//const ENABLE_SWITCH_STILE = true;
const ENABLE_SWITCH_STILE = false;

// prelievo IMMAGINI SITO da www.shoppingclick.it  
// =>/home/shouser10/htdocs/www.shoppingclick.it/myidea/storage-images/site-myideaonline-it
const URL_IMAGES_SITE = "https://www.shoppingclick.it/myidea/storage-images/site-myideaonline-it/";

// identificazione cliente
const CUSTOMER = "RossiDemo";
const CUSTOMER_CODE = "0001";
const CUSTOMER_VIA = "via Roma 10";
const CUSTOMER_CITTA = "20121 Milano";
const CUSTOMER_EMAIL = "informazioni@tosDemoAtt.it";

const CUSTOMER_SLIDE_URL_ARRAY = [
  "https://www.shoppingclick.it/myidea/storage-images/customer-0001/categorie/categoria-abbigliamentodonna.jpg",
  "https://www.shoppingclick.it/myidea/storage-images/customer-0001/categorie/categoria-accessoridonna.jpg",
  "https://www.shoppingclick.it/myidea/storage-images/customer-0001/categorie/categoria-attrezzigiardino.jpg",
  "https://www.shoppingclick.it/myidea/storage-images/customer-0001/categorie/categoria-computers.jpg",
  "https://www.shoppingclick.it/myidea/storage-images/customer-0001/categorie/categoria-games.jpg",
  "https://www.shoppingclick.it/myidea/storage-images/customer-0001/categorie/categoria-lavatrici.jpg",
  "https://www.shoppingclick.it/myidea/storage-images/customer-0001/categorie/categoria-trapani.jpg",
  "https://www.shoppingclick.it/myidea/storage-images/customer-0001/categorie/categoria-tablets.jpg",
];


// pagine di Menu

const PAGE_LANDING_TITOLO = "produzione e vendita TOSAERBA"
const PAGE_LANDING_TITOLO1 = "soluzioni per privati e professionisti"
const PAGE_LANDING_TESTO = "La nostra Azienda vanda 85 anni di esperienza. La passione di tre generazioni si traduce in qualità e prestazioni per tutti i nostri prodotti. \
Crediamo nella costante crescita dei metodi di produzione, associati alle nuove tecnologie. \
Il nostro team di ricerca e sviluppo lavora costantemente per trovare i materiali di alta qualità e le migliori lavorazioni.";
const PAGE_LANDING_SLIDE_1_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide4.jpg";
const PAGE_LANDING_SLIDE_1_TITOLO = "vantiamo Clienti di spicco";
const PAGE_LANDING_SLIDE_1_SOTTOTITOLO = "per garantirti la massima professionalità";
const PAGE_LANDING_SLIDE_2_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide5.jpg";
const PAGE_LANDING_SLIDE_2_TITOLO = "vantiamo Clienti di spicco";
const PAGE_LANDING_SLIDE_2_SOTTOTITOLO = "per garantirti la massima professionalità";
const PAGE_LANDING_SLIDE_3_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide6.jpg";
const PAGE_LANDING_SLIDE_3_TITOLO = "vantiamo Clienti di spicco";
const PAGE_LANDING_SLIDE_3_SOTTOTITOLO = "per garantirti la massima professionalità";


const PAGE_HOME_TITOLO = "produzione e vendita TOSAERBA"
const PAGE_HOME_TITOLO1 = ""
const PAGE_HOME_TESTO = "La nostra Azienda vanda 85 anni di esperienza. La passione di tre generazioni si traduce in qualità e prestazioni per tutti i nostri prodotti. \
Crediamo nella costante crescita dei metodi di produzione, associati alle nuove tecnologie. \
Il nostro team di ricerca e sviluppo lavora costantemente per trovare i materiali di alta qualità e le migliori lavorazioni.";
const PAGE_HOME_SLIDE_1_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide67.jpg";
const PAGE_HOME_SLIDE_1_TITOLO = "vantiamo Clienti di spicco";
const PAGE_HOME_SLIDE_1_SOTTOTITOLO = "per garantirti la massima professionalità";
const PAGE_HOME_SLIDE_2_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide35.jpg";
const PAGE_HOME_SLIDE_2_TITOLO = "vantiamo Clienti di spicco";
const PAGE_HOME_SLIDE_2_SOTTOTITOLO = "per garantirti la massima professionalità";
const PAGE_HOME_SLIDE_3_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide68.jpg";
const PAGE_HOME_SLIDE_3_TITOLO = "vantiamo Clienti di spicco";
const PAGE_HOME_SLIDE_3_SOTTOTITOLO = "per garantirti la massima professionalità";

const PAGE_LOGIN_TITOLO = "la Professionalità al tuo servizio";
const PAGE_LOGIN_TESTO = "Tutti i giorni con passione ci aggiorniamo e ci prepariamo per gestire al meglio le tue esigenze.  \
Assicuriamo la migliore assistenza perchè la nostra Storia vuole continuità. \
Il nostro Team è composto dai migliori Professionisti in grado di seguirti in tutte le fasi. \
Abbiamo a cuore le tue problematiche e garantiamo la migliore soluzione possibile.";
const PAGE_LOGIN_SLIDE_1_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide25.jpg";
const PAGE_LOGIN_SLIDE_1_TITOLO = "continuo aggiornamento";
const PAGE_LOGIN_SLIDE_1_SOTTOTITOLO = "per garantire le tecnologie più moderne";
const PAGE_LOGIN_SLIDE_2_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide26.jpg";
const PAGE_LOGIN_SLIDE_2_TITOLO = "sinergia con i nostri clienti";
const PAGE_LOGIN_SLIDE_2_SOTTOTITOLO = "decenni di collaborazioni pe migliorare i prodotti";
const PAGE_LOGIN_SLIDE_3_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide27.jpg";
const PAGE_LOGIN_SLIDE_3_TITOLO = "costante analisi delle performance";
const PAGE_LOGIN_SLIDE_3_SOTTOTITOLO = "per migliorare sempre";

const PAGE_REGISTRA_TITOLO = "dove facciamo la differenza";
const PAGE_REGISTRA_TESTO = "Il nostro Studio ritiene che la professione debba essere sempre e costantemente al passo con le nuove tecnologie.  \
Beneficiamo di modalità di comunicazione avanzate in modo da andare da seguire coloro che hanno poco tempo da dedicarci. \
Lo Studio, forte della ventennale esperienza dei Professionisti che lo compongono, ti offre la migliore competenza ai tuoi problemi. \
Le conoscenze offerte dal nostro team non sono seconde a nessuno. Il nostro team di ricerca e sviluppo lavora costantemente per raggiungere le migliori soluzioni";
const PAGE_REGISTRA_SLIDE_1_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide44.jpg";
const PAGE_REGISTRA_SLIDE_1_TITOLO = "massima professionalità";
const PAGE_REGISTRA_SLIDE_1_SOTTOTITOLO = "i vostri problemi meritano la nostra soluzione";
const PAGE_REGISTRA_SLIDE_2_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide62.jpg";
const PAGE_REGISTRA_SLIDE_2_TITOLO = "sinergia con i nostri clienti";
const PAGE_REGISTRA_SLIDE_2_SOTTOTITOLO = "la ricchezza della nostra azienda sono le persone";
const PAGE_REGISTRA_SLIDE_3_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide72.jpg";
const PAGE_REGISTRA_SLIDE_3_TITOLO = "assistenza e consulenza";
const PAGE_REGISTRA_SLIDE_3_SOTTOTITOLO = "le domande dei nostri clienti  meritano soluzioni immediate";


const PAGE_CHISIAMO_TITOLO = "La nostra storia";
const PAGE_CHISIAMO_TESTO = "Fondato nel 1948, la nostra azienda fornisce prodotti basati da lavorazioni di primo ordine. Noi sappiamo come creare attrezzature destinate ad uso intenso nel tempo.\
Vantiamo una clientela di assoluto rilievo e il continuo investimento sulla formazione ti garantisce l'utilizzo dei macchinari più evoluti per costruire nella massima sicurezza. \
Il nostro obiettivo è semplice: fornire ai nostri clienti prodotti di altissima qualità ai prezzi più competitivi.";
const PAGE_CHISIAMO_SLIDE_1_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide69.jpg";
const PAGE_CHISIAMO_SLIDE_1_TITOLO = "la migliore competenza";
const PAGE_CHISIAMO_SLIDE_1_SOTTOTITOLO = "i migliori prodotti, le tecnologie più affidabili";
const PAGE_CHISIAMO_SLIDE_2_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide70.jpg";
const PAGE_CHISIAMO_SLIDE_2_TITOLO = "tecnologie all'avanguardia";
const PAGE_CHISIAMO_SLIDE_2_SOTTOTITOLO = "servizio di manutenzione e ripristino";
const PAGE_CHISIAMO_SLIDE_3_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide71.jpg";
const PAGE_CHISIAMO_SLIDE_3_TITOLO = "eccellente garanzia ";
const PAGE_CHISIAMO_SLIDE_3_SOTTOTITOLO = "il nostro Team ti aspetta per rispondere al meglio alle tue richieste";

const PAGE_CONTATTI_TITOLO = "Contatti";
const PAGE_CONTATTI_TESTO = "saremo lieti di risponderti al più presto";
const PAGE_CONTATTI_SLIDE_1_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide23.jpg";
const PAGE_CONTATTI_SLIDE_1_TITOLO = "elevatissimo standard di servizio";
const PAGE_CONTATTI_SLIDE_1_SOTTOTITOLO = "la nostra esperienza a disposizione per la tua scelta";
const PAGE_CONTATTI_SLIDE_2_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide44.jpg";
const PAGE_CONTATTI_SLIDE_2_TITOLO = "altissimo livello di assistenza";
const PAGE_CONTATTI_SLIDE_2_SOTTOTITOLO = "servizio 24/7";
const PAGE_CONTATTI_SLIDE_3_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide62.jpg";
const PAGE_CONTATTI_SLIDE_3_TITOLO = "qualità e cortesia";
const PAGE_CONTATTI_SLIDE_3_SOTTOTITOLO = "ti aspettiamo per qualsiasi informazione";

const PAGE_EXPERTISE_TITOLO = "La nostra passione, la nostra esperienza";
const PAGE_EXPERTISE_TESTO = "Forniamo  prodotti basati da lavorazioni di primo ordine. Noi sappiamo come creare attrezzature destinate ad uso intenso nel tempo.\
Vantiamo una clientela di assoluto rilievo e il continuo investimento sulla formazione ti garantisce l'utilizzo dei macchinari più evoluti per costruire nella massima sicurezza. \
Il nostro obiettivo è semplice: fornire ai nostri clienti prodotti di altissima qualità ai prezzi più competitivi.";
const PAGE_EXPERTISE_SLIDE_1_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide26.jpg";
const PAGE_EXPERTISE_SLIDE_1_TITOLO = "84 anni di esperienza";
const PAGE_EXPERTISE_SLIDE_1_SOTTOTITOLO = "disponiamo delle migliori professionalità";
const PAGE_EXPERTISE_SLIDE_2_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide72.jpg";
const PAGE_EXPERTISE_SLIDE_2_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_EXPERTISE_SLIDE_2_SOTTOTITOLO = "disponiamo di un Team ricerca e sviluppo";
const PAGE_EXPERTISE_SLIDE_3_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide27.jpg";
const PAGE_EXPERTISE_SLIDE_3_TITOLO = "consolidati nel tempo";
const PAGE_EXPERTISE_SLIDE_3_SOTTOTITOLO = "affidabili in tutto";

const PAGE_PRODOTTI_TITOLO = "I migliori tosaerba";
const PAGE_PRODOTTI_TESTO = "Fondato nel 1986, il nostro Studio offre assistenza e soluzioni ecellenti commisurate alla nostra dedizione. \
Vantiamo una clientela di assoluto rilievo e il continuo investimento sulla formazione ti garantisce il miglior esito ai tuoi problemi. \
Il nostro team ha una vasta conoscenza del settore. Il nostro obiettivo è semplice: fornire ai nostri clienti consulenza di altissima qualità ai prezzi più competitivi. \
Le conoscenze offerte dal nostro team non sono seconde a nessuno. Il nostro team di ricerca e sviluppo lavora costantemente per rilevare le novità e le migliori soluzioni.";
const PAGE_PRODOTTI_SLIDE_1_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/prodotti/tosaerba-batteria.png";
const PAGE_PRODOTTI_SLIDE_1_TITOLO = "Tosaerba a batteria";
const PAGE_PRODOTTI_SLIDE_1_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_PRODOTTI_SLIDE_2_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/prodotti/tosaerba-elettrici.png";
const PAGE_PRODOTTI_SLIDE_2_TITOLO = "Tosaerba elettrico";
const PAGE_PRODOTTI_SLIDE_2_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_PRODOTTI_SLIDE_3_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/prodotti/tosaerba-scoppio.png";
const PAGE_PRODOTTI_SLIDE_3_TITOLO = "Tosaerba a Scoppio";
const PAGE_PRODOTTI_SLIDE_3_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_PRODOTTI_PRODOTTO1_DESCR = "Tosaerba a batteria";
const PAGE_PRODOTTI_PRODOTTO1_DESCR1 = " ";
const PAGE_PRODOTTI_PRODOTTO1_PREZZO = "€ 146,00";
const PAGE_PRODOTTI_PRODOTTO2_DESCR = "Tosaerba elettrico";
const PAGE_PRODOTTI_PRODOTTO2_DESCR1 = "+ 3 email";
const PAGE_PRODOTTI_PRODOTTO2_PREZZO = "€ 187,00";
const PAGE_PRODOTTI_PRODOTTO3_DESCR = "Tosaerba a Scoppiopio";
const PAGE_PRODOTTI_PRODOTTO3_DESCR1 = "";
const PAGE_PRODOTTI_PRODOTTO3_PREZZO = "€ 212,00 ";
                
const PAGE_GIFTCARD_TITOLO = "Seguici"
const PAGE_GIFTCARD_TESTO = "Seguiamo costantemente Convegni del settore perchè conosciamo l'importanza dei continui aggiornamenti professionali. \
Crediamo nella costante crescita delle Professionalità. Riteniamo fondamentale il continuo aggiornamento dei nostri Professionisti. \
Abbiamo a cuore le tue problematiche e garantiamo la migliore soluzione possibile.";
const PAGE_GIFTCARD_SLIDE_1_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide61.jpg";
const PAGE_GIFTCARD_SLIDE_1_TITOLO = "Pubblicazioni per lasciare il segno della nostra professionalità";
const PAGE_GIFTCARD_SLIDE_1_SOTTOTITOLO = "Eveti per condividere informazioni e metodologie";
const PAGE_GIFTCARD_SLIDE_2_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide62.jpg";
const PAGE_GIFTCARD_SLIDE_2_TITOLO = "Pubblicazioni per lasciare il segno della nostra professionalità";
const PAGE_GIFTCARD_SLIDE_2_SOTTOTITOLO = "Eveti per condividere informazioni e metodologie";
const PAGE_GIFTCARD_SLIDE_3_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide63.jpg";
const PAGE_GIFTCARD_SLIDE_3_TITOLO = "Pubblicazioni per lasciare il segno della nostra professionalità";
const PAGE_GIFTCARD_SLIDE_3_SOTTOTITOLO = "Eveti per condividere informazioni e metodologie";
                
const PAGE_NEWS_TITOLO = "Seguici"
const PAGE_NEWS_TESTO = "Instauriamo con i nostri clienti una profiqua comunicazione su tutto ciò che può interessare la miglior soluzione praticabile. \
Crediamo nel continuo esame delle problematiche al fine di rettificare quanto inerente le esigenze dei Clienti. Riteniamo fondamentale il continuo aggiornamento dei nostri Professionisti. \
Abbiamo a cuore le tue problematiche e garantiamo la migliore soluzione possibile.";
const PAGE_NEWS_SLIDE_1_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide64.jpg";
const PAGE_NEWS_SLIDE_1_TITOLO = "condividiamo conoscenze e modi di pensare";
const PAGE_NEWS_SLIDE_1_SOTTOTITOLO = "pubblichiamo approfondimenti";
const PAGE_NEWS_SLIDE_2_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide65.jpg";
const PAGE_NEWS_SLIDE_2_TITOLO = "condividiamo conoscenze e modi di pensare";
const PAGE_NEWS_SLIDE_2_SOTTOTITOLO = "pubblichiamo approfondimenti";
const PAGE_NEWS_SLIDE_3_URL = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide66.jpg";
const PAGE_NEWS_SLIDE_3_TITOLO = "condividiamo conoscenze e modi di pensare";
const PAGE_NEWS_SLIDE_3_SOTTOTITOLO = "pubblichiamo approfondimenti";


// prelievo IMMAGINI CLIENTE da www.shoppingclick.it (non utilizzato)
// =>/home/shouser10/htdocs/www.shoppingclick.it/myidea/storage-images/site-myideaonline-it
//const URL_IMAGES_CUSTOMER = "https://www.shoppingclick.it/myidea/storage-images/customer-"+CUSTOMER_CODE+"/";
const URL_IMAGES_CUSTOMER = "/images-"+CUSTOMER_CODE+"/";

const URL_IMAGES_CATEGORY = "https://www.shoppingclick.it/myidea/storage-images/customer-0001/categories/";
const URL_IMAGES_PRODUCT = "https://www.shoppingclick.it/myidea/storage-images/customer-0001/products/";

// IMMAGINI CLIENTE da www.shoppingclick.it (non utilizzato)
const URL_IMAGES_CUST_LOGIN = "/login.jpg";
const URL_IMAGES_CUST_CHISIAMO = "/chisiamo.jpg";
const URL_IMAGES_CUST_CONTATTI = "/contatti.jpg";
const URL_IMAGES_CUST_COUPONS = "/coupons.jpg";
const URL_IMAGES_CUST_GIFTCARD = "/giftcard.jpg";


module.exports = { 
  TITLE_PRODUCT,
  ENABLE_SWITCH_STILE,
  SITE_STYLE_ACTIVE,
  URL_IMAGES_SITE,
  URL_IMAGES_CATEGORY,
  URL_IMAGES_PRODUCT,
  CUSTOMER,
  CUSTOMER_CODE,
  CUSTOMER_VIA,
  CUSTOMER_CITTA,
  CUSTOMER_EMAIL,
  CUSTOMER_SLIDE_URL_ARRAY,

  PAGE_LOGIN_TITOLO,
  PAGE_LOGIN_TESTO,
  PAGE_LOGIN_SLIDE_1_URL,
  PAGE_LOGIN_SLIDE_1_TITOLO,
  PAGE_LOGIN_SLIDE_1_SOTTOTITOLO,
  PAGE_LOGIN_SLIDE_2_URL,
  PAGE_LOGIN_SLIDE_2_TITOLO,
  PAGE_LOGIN_SLIDE_2_SOTTOTITOLO,
  PAGE_LOGIN_SLIDE_3_URL,
  PAGE_LOGIN_SLIDE_3_TITOLO,
  PAGE_LOGIN_SLIDE_3_SOTTOTITOLO,

  PAGE_REGISTRA_TITOLO,
  PAGE_REGISTRA_TESTO,
  PAGE_REGISTRA_SLIDE_1_URL,
  PAGE_REGISTRA_SLIDE_1_TITOLO,
  PAGE_REGISTRA_SLIDE_1_SOTTOTITOLO,
  PAGE_REGISTRA_SLIDE_2_URL,
  PAGE_REGISTRA_SLIDE_2_TITOLO,
  PAGE_REGISTRA_SLIDE_2_SOTTOTITOLO,
  PAGE_REGISTRA_SLIDE_3_URL,
  PAGE_REGISTRA_SLIDE_3_TITOLO,
  PAGE_REGISTRA_SLIDE_3_SOTTOTITOLO,

  PAGE_HOME_TITOLO,
  PAGE_HOME_TITOLO1,
  PAGE_HOME_TESTO,
  PAGE_HOME_SLIDE_1_URL,
  PAGE_HOME_SLIDE_1_TITOLO,
  PAGE_HOME_SLIDE_1_SOTTOTITOLO,
  PAGE_HOME_SLIDE_2_URL,
  PAGE_HOME_SLIDE_2_TITOLO,
  PAGE_HOME_SLIDE_2_SOTTOTITOLO,
  PAGE_HOME_SLIDE_3_URL,
  PAGE_HOME_SLIDE_3_TITOLO,
  PAGE_HOME_SLIDE_3_SOTTOTITOLO,

  PAGE_CHISIAMO_TITOLO,
  PAGE_CHISIAMO_TESTO, 
  PAGE_CHISIAMO_SLIDE_1_URL,
  PAGE_CHISIAMO_SLIDE_1_TITOLO,
  PAGE_CHISIAMO_SLIDE_1_SOTTOTITOLO,
  PAGE_CHISIAMO_SLIDE_2_URL,
  PAGE_CHISIAMO_SLIDE_2_TITOLO,
  PAGE_CHISIAMO_SLIDE_2_SOTTOTITOLO,
  PAGE_CHISIAMO_SLIDE_3_URL,
  PAGE_CHISIAMO_SLIDE_3_TITOLO,
  PAGE_CHISIAMO_SLIDE_3_SOTTOTITOLO,

  PAGE_CONTATTI_TITOLO,
  PAGE_CONTATTI_TESTO,
  PAGE_CONTATTI_SLIDE_1_URL,
  PAGE_CONTATTI_SLIDE_1_TITOLO,
  PAGE_CONTATTI_SLIDE_1_SOTTOTITOLO,
  PAGE_CONTATTI_SLIDE_2_URL,
  PAGE_CONTATTI_SLIDE_2_TITOLO,
  PAGE_CONTATTI_SLIDE_2_SOTTOTITOLO,
  PAGE_CONTATTI_SLIDE_3_URL,
  PAGE_CONTATTI_SLIDE_3_TITOLO,
  PAGE_CONTATTI_SLIDE_3_SOTTOTITOLO,  

  PAGE_EXPERTISE_TITOLO,
  PAGE_EXPERTISE_TESTO,
  PAGE_EXPERTISE_SLIDE_1_URL,
  PAGE_EXPERTISE_SLIDE_1_TITOLO,
  PAGE_EXPERTISE_SLIDE_1_SOTTOTITOLO,
  PAGE_EXPERTISE_SLIDE_2_URL,
  PAGE_EXPERTISE_SLIDE_2_TITOLO,
  PAGE_EXPERTISE_SLIDE_2_SOTTOTITOLO,
  PAGE_EXPERTISE_SLIDE_3_URL,
  PAGE_EXPERTISE_SLIDE_3_TITOLO,
  PAGE_EXPERTISE_SLIDE_3_SOTTOTITOLO,

  PAGE_PRODOTTI_TITOLO,
  PAGE_PRODOTTI_TESTO,
  PAGE_PRODOTTI_SLIDE_1_URL,
  PAGE_PRODOTTI_SLIDE_1_TITOLO,
  PAGE_PRODOTTI_SLIDE_1_SOTTOTITOLO,
  PAGE_PRODOTTI_SLIDE_2_URL,
  PAGE_PRODOTTI_SLIDE_2_TITOLO,
  PAGE_PRODOTTI_SLIDE_2_SOTTOTITOLO,
  PAGE_PRODOTTI_SLIDE_3_URL,
  PAGE_PRODOTTI_SLIDE_3_TITOLO,
  PAGE_PRODOTTI_SLIDE_3_SOTTOTITOLO,
  PAGE_PRODOTTI_PRODOTTO1_DESCR,
  PAGE_PRODOTTI_PRODOTTO1_DESCR1,  
  PAGE_PRODOTTI_PRODOTTO1_PREZZO,
  PAGE_PRODOTTI_PRODOTTO2_DESCR,
  PAGE_PRODOTTI_PRODOTTO2_DESCR1,  
  PAGE_PRODOTTI_PRODOTTO2_PREZZO,
  PAGE_PRODOTTI_PRODOTTO3_DESCR,
  PAGE_PRODOTTI_PRODOTTO3_DESCR1,  
  PAGE_PRODOTTI_PRODOTTO3_PREZZO,
  
  PAGE_LANDING_TITOLO,
  PAGE_LANDING_TITOLO1,
  PAGE_LANDING_TESTO,
  PAGE_LANDING_SLIDE_1_URL,
  PAGE_LANDING_SLIDE_1_TITOLO,
  PAGE_LANDING_SLIDE_1_SOTTOTITOLO,
  PAGE_LANDING_SLIDE_2_URL,
  PAGE_LANDING_SLIDE_2_TITOLO,
  PAGE_LANDING_SLIDE_2_SOTTOTITOLO,
  PAGE_LANDING_SLIDE_3_URL,
  PAGE_LANDING_SLIDE_3_TITOLO,
  PAGE_LANDING_SLIDE_3_SOTTOTITOLO,

  PAGE_GIFTCARD_TITOLO,
  PAGE_GIFTCARD_TESTO,
  PAGE_GIFTCARD_SLIDE_1_URL,
  PAGE_GIFTCARD_SLIDE_1_TITOLO,
  PAGE_GIFTCARD_SLIDE_1_SOTTOTITOLO,
  PAGE_GIFTCARD_SLIDE_2_URL,
  PAGE_GIFTCARD_SLIDE_2_TITOLO,
  PAGE_GIFTCARD_SLIDE_2_SOTTOTITOLO,
  PAGE_GIFTCARD_SLIDE_3_URL,
  PAGE_GIFTCARD_SLIDE_3_TITOLO,
  PAGE_GIFTCARD_SLIDE_3_SOTTOTITOLO,
   
  PAGE_NEWS_TITOLO,
  PAGE_NEWS_TESTO,
  PAGE_NEWS_SLIDE_1_URL,
  PAGE_NEWS_SLIDE_1_TITOLO,
  PAGE_NEWS_SLIDE_1_SOTTOTITOLO,
  PAGE_NEWS_SLIDE_2_URL,
  PAGE_NEWS_SLIDE_2_TITOLO,
  PAGE_NEWS_SLIDE_2_SOTTOTITOLO,
  PAGE_NEWS_SLIDE_3_URL,
  PAGE_NEWS_SLIDE_3_TITOLO,
  PAGE_NEWS_SLIDE_3_SOTTOTITOLO,
  
  URL_IMAGES_CUSTOMER,
  URL_IMAGES_CUST_LOGIN,
  URL_IMAGES_CUST_CHISIAMO,
  URL_IMAGES_CUST_CONTATTI,
  URL_IMAGES_CUST_COUPONS,
  URL_IMAGES_CUST_GIFTCARD
};
